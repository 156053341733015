<template>
  <el-dialog
    :title="todo ? '【详情】注册信息' : '【修改】注册信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <div class="modularLabel modularLabel1">
        <label>基本信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="驾驶员姓名" prop="name">
              <el-input v-model="dataForm.name" placeholder="驾驶员姓名" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所属车队" prop="motorcadeId">
              <el-select class="item-choose" v-model="dataForm.motorcadeId" :disabled="todo" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in motorcadeList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系电话" prop="urgentPhone">
              <el-input v-model="dataForm.urgentPhone" placeholder="可输入手机号码或固定电话" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="注册城市" prop="registerCity">
              <el-input v-model="dataForm.registerCity" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号码" prop="cardNo">
              <el-input v-model="dataForm.cardNo" placeholder="身份证号码" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="现居地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="现居地址" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="是否自带车辆" prop="isHaveVehicle">
              <el-select class="item-choose" v-model="dataForm.isHaveVehicle" :disabled="todo" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="准驾类型" prop="proofType">
              <el-select class="item-choose" v-model="dataForm.proofType" :disabled="todo" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.drive_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel1" v-if="dataForm.isHaveVehicle === 1">
        <label>车辆信息</label>
      </div>
      <div class="modularBox modularBox1" v-if="dataForm.isHaveVehicle === 1">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车牌号" prop="bindVehicleInfo.vehiclePlate">
              <el-input v-model="dataForm.bindVehicleInfo.vehiclePlate" placeholder="车牌号" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否有尾板" prop="bindVehicleInfo.isTailstock">
              <el-select class="item-choose" v-model="dataForm.bindVehicleInfo.isTailstock"
                         :disabled="todo" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="能源类型" prop="bindVehicleInfo.energyType">
              <el-select class="item-choose" v-model="dataForm.bindVehicleInfo.energyType" :disabled="todo" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.energy_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆类型" prop="bindVehicleInfo.vehicleType">
              <el-select class="item-choose" :disabled="todo" v-model="dataForm.bindVehicleInfo.vehicleType" style="width: 100%;" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.vehicle_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="进门宽(cm)" prop="bindVehicleInfo.enterDoorWidth">
              <el-input v-model="dataForm.bindVehicleInfo.enterDoorWidth" placeholder="请输入" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进门高(cm)" prop="bindVehicleInfo.enterDoorHeight">
              <el-input v-model="dataForm.bindVehicleInfo.enterDoorHeight" placeholder="请输入" :disabled="todo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="modularLabel modularLabel1">
        <label>资料信息</label>
      </div>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="身份证正面">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: cardType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(cardType)" :src="getPhotoType(cardType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证反面">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: otherCardType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(otherCardType)" :src="getPhotoType(otherCardType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="驾驶证">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: driverProofType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(driverProofType)" :src="getPhotoType(driverProofType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="从业资格证">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: qualificationType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(qualificationType)" :src="getPhotoType(qualificationType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" v-if="dataForm.isHaveVehicle === 1">
        <el-col :span="12">
          <el-form-item label="行驶证">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: vehicleDrivingType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(vehicleDrivingType)" :src="getPhotoType(vehicleDrivingType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆45度角">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: vehicleType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(vehicleType)" :src="getPhotoType(vehicleType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" v-if="dataForm.isHaveVehicle === 1">
        <el-col :span="12">
          <el-form-item label="交强险">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: vehicleForceType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(vehicleForceType)" :src="getPhotoType(vehicleForceType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商业险">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: vehicleBusinessType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(vehicleBusinessType)" :src="getPhotoType(vehicleBusinessType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" v-if="dataForm.isHaveVehicle === 1">
        <el-col :span="12">
          <el-form-item label="车辆营运证">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :disabled="todo"
              :data="{businessType: vehicleTransportType}"
              :headers="{'access_token': accessToken}"
              :on-success="saveFileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              :show-file-list="false">
              <img v-if="getPhotoType(vehicleTransportType)" :src="getPhotoType(vehicleTransportType)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!todo">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isPhone, isMobile, isCard } from '@/utils/validate'
export default {
  data () {
    const validateMobile = (rule, value, callback) => {
      if (rule.field === 'urgentPhone') {
        if (!value) {
          callback()
        }
      }
      if (!isMobile(value) && !isPhone(value)) {
        callback(new Error('格式错误，可输入手机号码或固定电话'))
      } else {
        callback()
      }
    }
    const validateCard = (rule, value, callback) => {
      if (!isCard(value)) {
        callback(new Error('身份证号码格式错误'))
      } else {
        callback()
      }
    }
    return {
      todo: true,
      visible: false,
      cardType: 5000,
      otherCardType: 5010,
      driverProofType: 5001,
      qualificationType: 5011,
      vehicleDrivingType: 3000,
      vehicleType: 3001,
      vehicleTransportType: 3002,
      vehicleForceType: 9040,
      vehicleBusinessType: 9041,
      dataForm: {
        motorcadeId: null
      },
      areaList: [],
      motorcadeList: [],
      uploadUrl: '',
      accessToken: '',
      isShow: true,
      dataRule: {
        name: [
          { required: true, message: '驾驶员姓名不能为空', trigger: 'blur' }
        ],
        motorcadeId: [
          { required: true, message: '请选择所属车队', trigger: 'change' }
        ],
        cardNo: [
          { required: true, message: '身份证号码不能为空', trigger: 'blur' },
          { validator: validateCard, trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        urgentPhone: [
          // { required: true, message: '紧急联系电话不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        proofType: [
          { required: true, message: '请选择驾驶证类型', trigger: 'change' }
        ],
        isHaveVehicle: [
          { required: true, message: '请选择是否自带车辆', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.dataForm.enclosureInfoList = []
    },
    initFileList (data) {
      if (data.enclosureInfoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
      }
    },
    // 获取省市区信息
    getAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/register/findCityList`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.areaList = data
      })
    },
    getPhotoType (type) {
      let enclosureInfoList = this.dataForm.enclosureInfoList
      if (!enclosureInfoList) {
        return ''
      }
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === type) {
          return 'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/' + enclosureInfoList[i].url
        }
      }
      return ''
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.motorcadeList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/driver/getByAudit/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            this.dataForm = data
            this.initFileList(data)
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/driver/updateAudit`),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      let enclosureInfoList = this.dataForm.enclosureInfoList
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === resData.businessType) {
          enclosureInfoList.splice(i, 1)
          break
        }
      }
      enclosureInfoList.push({ id: resData.id, businessType: resData.businessType, url: resData.url })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>

<style lang="less">
  .modularLabel {
    margin-bottom: 20px;

    label {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
